.SideBar {
	flex: 0 0 20%;
	background-color: var(--color-gray-d);
	padding: 3rem 0;
	
	@media only screen and (max-width: 56.25em) {
		display: none;
		width:0;
	}
	
	&--active {	
		@media only screen and (max-width: 56.25em) {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width:100%;
			height: 100vh;
		}
	}
}

.SideBar-icon {
	display: none;
	font-size: 2rem;
	position: fixed;
	bottom: 4rem;
	left: 0;
	width: 4rem;
	height: 4rem;
	padding: 1rem;
	color: var(--color-white);
	background-color: var(--color-gray-d);
	cursor: pointer;
	z-index:1000;
	
	@media only screen and (max-width: 56.25em) {
		display: block;
	}
	
	&--active {
		@media only screen and (max-width: 56.25em) {
			bottom: 0;
		}
	}
}