.SearchForm {
	flex: 0 0 40%;
	
	@media only screen and (max-width: 56.25em) {
		grid-row: 2 / -1;
		grid-column: 1 / span 2;
	}
	
	&__form {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.4rem;
		width: 100%;
	}
	
	&__input {
		font-family: inherit;
		font-size: inherit;
		color: inherit;
		background-color: var(--color-gray-l);
		border: none;
		border-radius: 100px;
		padding: 1rem 1.5rem;
		margin-right: -3rem;
		transition: all .5s;
		width: 40%;
		
		&:focus {
			outline: none;
			background-color: var(--color-gray-m);
			width: 100%;
		}
		
		@media only screen and (max-width: 56.25em) {
			width: 100%;
		}
	}
	
	&__button {
		border: none;
		background-color: var(--color-gray-l);
		cursor: pointer;
		transition: all .5s;
		
		&:focus {
			outline: none;
		}
	}
	
	&__input:focus + &__button {
		background-color: var(--color-gray-m);
	}
	
	& .IconSearch {
		height: 2rem;
		width: 2rem;
		fill: var(--color-gray-d);
	}
	
	&--in-focus {
		
		.SearchForm__input {
			background-color: var(--color-gray-m);
			width: 100%;
		}
		
		.SearchForm__button {
			background-color: var(--color-gray-m);
		}
	}
}