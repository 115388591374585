.NewsItem {
	padding: var(--block-pad);
	background-color: var(--color-white);
	box-shadow: var(--shadow);
	
	@media only screen and (max-width: 56.25em) {
		padding: var(--block-pad-sm);
	}
	
	&__heading {
		grid-column: 1 / -1;
	}
	
	&__link:link,
	&__link:visited {
		color: inherit;
		text-decoration: none;
	}
	
	&__link:hover,
	&__link:active {
		color: var(--color-secondary);
	}
	
	&__content {
		display: flex;
		
		@media only screen and (max-width: 56.25em) {
			flex-direction: column;
		}
	}
	
	&__img {
		width: 40%;
		height: 100%;
		margin-right: 2rem;
		
		@media only screen and (max-width: 56.25em) {
			width: 100%;
			margin-bottom: 2rem;
		}
	}
	
	&__atrbs {
		font-size: 1.4rem;
	}
	
	&__atrbs > *:not(:last-child) {
		margin-right: 1rem;
		padding-right: 1rem;
		border-right: var(--border-m);
	}
	
	&__source:link,
	&__source:visited,
	&__category:link,
	&__category:visited {
		color: var(--color-secondary);
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 700;
	}
	
	&__source:active,
	&__source:hover,
	&__category:active,
	&__category:hover {
		color: var(--color-primary);
	}
	
	&__time {
		color: var(--color-gray-m);
	}
	
	&__lead {
		font-size: 1.6rem;
	}
	
	&--read &__link:link,
	&--read &__link:visited,
	&--read &__lead,
	&--read &__source:link,
	&--read &__source:visited,
	&--read &__category:link,
	&--read &__category:visited {
		color: var(--color-gray-m);
	}
	
	&--read &__link:hover,
	&--read &__link:active,
	&--read &__source:hover,
	&--read &__source:active,
	&--read &__category:hover,
	&--read &__category:active {
		color: var(--color-secondary);
	}
}