.Article {
	background-color: var(--color-gray-l);
	
	&__container {
		margin: var(--block-pad);
		padding: var(--block-pad);
		background-color: var(--color-white);
		box-shadow: var(--shadow);
	}
	
	&__link:link,
	&__link:visited {
		color: var(--color-primary);
		text-decoration: none;
	}
	
	&__link:hover,
	&__link:active {
		color: var(--color-secondary);
	}
	
	&__img {
		width: 100%;
	}
	
	&__atrbs {
		font-size: 1.4rem;
	}
	
	&__atrbs > *:not(:last-child) {
		margin-right: 1rem;
		padding-right: 1rem;
		border-right: var(--border-m);
	}
	
	&__source:link,
	&__source:visited,
	&__category:link,
	&__category:visited {
		color: var(--color-secondary);
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 700;
	}
	
	&__source:active,
	&__source:hover,
	&__category:active,
	&__category:hover {
		color: var(--color-primary);
	}
	
	&__time {
		color: var(--color-gray-m);
	}
	
	&__body {
		font-size: 1.6rem;
	}
	
	&__body p:not(:last-child) {
		margin-bottom: 1rem;
	}
	
	&__url-original {
		font-size: 1.4rem;
		
		& a:link,
		& a:visited {
			margin-left: 1rem;
			color: var(--color-secondary);
			text-decoration: none;
			word-break: break-word;
		}
		
		& a:hover,
		& a:active {
			color: var(--color-primary);
		}
	}
}