.Header {
	height: 10rem;
	background-color: var(--color-white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 3rem;
	
	@media only screen and (max-width: 56.25em) {
		height: 14rem;
		align-content: center;
		display: grid;
		grid-template-rows: repeat(2, min-content);
		grid-template-columns: repeat(2, calc(50% - 3rem));
		grid-gap: 2rem;
	}
	
	&__logo-svg {
		width: 18rem;
		
		& path {
			fill: var(--color-primary);
		}
	}
}