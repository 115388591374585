/************ VARIABLES *************/
:root {
	//colors
	--color-primary: #FB9985;
	--color-secondary: #9EDEA0;
	--color-gray-d: #48484A;
	--color-gray-m: #C3C3C3;
	--color-gray-l: #F6F4F2;
	--color-black: #302020;
	--color-white: #fff;
	
	//font-size
	--default-font-size: 1.6rem;
	
	//borders
	--border-d: 1px solid var(--color-gray-d);
	--border-m: 1px solid var(--color-gray-m);
	--border-l: 1px solid var(--color-gray-l);
	--border-section: 2px solid var(--color-secondary);
	
	//shadows
	--shadow: 0 2rem 3rem rgba(0, 0, 0, .1);
	
	//padding
	--block-pad: 4rem;
	--block-pad-sm: 3rem;
}

/************ BASE *************/
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
}

body {
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-black);
	background-color: var(--color-primary);
	min-height: 100vh;
}

::selection {
	background-color: var(--color-primary);
	color: var(--color-gray-l);
}

.App {
	max-width: 120rem;
	margin: 2rem auto;
	background-color: var(--color-gray-l);
	box-shadow: var(--shadow);
	
	@media only screen and (max-width: 75em) {
		margin: 0 auto;
	}
}

.Content {
	display: flex;
	min-height: 75vh;
	position: relative;
}

/************ TYPOGRAPHY *************/
.heading-1 {
	font-size: 3rem;
	text-transform: uppercase;
	color: var(--color-primary);
}

.heading-2 {
	font-size: 2rem;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 0.1em;
	color: var(--color-black);
}

.heading-3 {
	font-size: 2rem;
	text-transform: uppercase;
	color: var(--color-primary);
}

/************ UTILITIES *************/
.mb-xs {
	margin-bottom: 1rem;
}

.mb-s {
	margin-bottom: 2rem;
	
	@media only screen and (max-width: 56.25em) {
		margin-bottom: 1rem;
	}
}

.mb-m {
	margin-bottom: 4rem;
	
	@media only screen and (max-width: 56.25em) {
		margin-bottom: 3rem;
	}
}

.mb-l {
	margin-bottom: 6rem;
	
	@media only screen and (max-width: 56.25em) {
		margin-bottom: 4rem;
	}
}

@media only screen and (max-width: 37.5em) {
	//0-600px phone
}

@media only screen and (max-width: 56.25em) {
	//600-900px tablet portrait
}

@media only screen and (max-width: 75em) {
	//900-1200px tablet landscape
}

@media only screen and (max-width: 112.5em) {
	//1200-1800px laptop/normal
}