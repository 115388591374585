.NotFound {
	flex: 1 1;
	text-align: center;
	
	&__heading-1 {
		font-size: 15rem;
	}
	
	&__text {
		font-size: 4rem;
	}
}