.AuthBlock {
	& button {
		border: none;
		background-color: transparent;
		font-size: 1.2rem;
		font-weight: 700;
		text-transform: uppercase;
		color: var(--color-primary);
		cursor: pointer;

		&:hover {
			color: var(--color-secondary);
		}

		&:focus {
			outline: none;
			transform: scale(1.1);
		}
	}
	
	@media only screen and (max-width: 56.25em) {
		justify-self: end;
	}
}

.Profile {
	display: grid;
	grid-template-rows: repeat(2, min-content);
	grid-template-columns: 5rem 1fr;
	grid-gap: .5rem 1rem;
	
	&__img {
		width: 5rem;
		height: 5rem;
		border-radius: 50%;
		grid-row: 1 / span 2;
	}
	
	&__name {
		align-self: end;
		font-size: 1.2rem;
	}
	
	&__btn-container {
		align-self: start;
		text-align: center;
	}
}