.SideMenu {
	list-style: none;
	font-size: 1.4rem;
	text-transform: uppercase;
	
	&__item {
		position: relative;
		
		&--anim {
			cursor: pointer;
			padding: 1.3rem 2rem;
		}
		
		&--anim::before {
			content: "";
			width: .5rem;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: width .4s cubic-bezier(1, 0, 0, 1) .2s, background-color .1s;
		}
		
		&--active::before {
			background-color: var(--color-secondary);
		}
		
		&--anim:hover::before {
			background-color: var(--color-secondary);
			width: 100%;
		}
	}
	
	&__link:link,
	&__link:visited,
	&__link {
		display: flex;
		align-items: center;
		color: var(--color-white);
		text-decoration: none;
		position: relative;
		z-index: 10;
		transition: all .6s;
	}
	
	&__link--button {
		font-size: inherit;
		padding: 1.3rem 2rem;
		cursor: pointer;
		background-color: transparent;
		border: none;
		text-transform: uppercase;
		
		&:focus {
			outline: none;
		}
	}
	
	&__link--button:hover {
		color: var(--color-secondary);
	}
	
	&__item--anim:hover &__link {
		color: var(--color-black);
	}
	
	&__item--active &__link:link,
	&__item--active &__link:visited {
		color: var(--color-secondary);
	}
	
	&__item--active &__link:hover,
	&__item--active &__link:active {
		color: var(--color-black);
	}
	
	& svg,
	&__icon {
		width: 2rem;
		height: 2rem;
		margin-right: 2rem;
		padding-top: .3rem;
		fill: currentcolor;
	}
	
	&__sub-list {
		list-style: inherit;
		display: none;
		transition: all .5s;
		
		&--active {
			display: block;
		}
	}
	
	&__sub-list &__link {
		padding: 0 2rem;
	}
}