.Footer {
	padding: var(--block-pad);
	background-color: var(--color-secondary);
	
	&__text,
	&__copyright {
		font-size: 1.4rem;
	}
	
	& a:link,
	& a:visited {
		color: var(--color-black);
	}
	
	& a:hover,
	& a:active {
		color: var(--color-primary);
		text-decoration: none;
	}
	
}