.NewsLine {
	background-color: var(--color-gray-l);
	
	&__container {
		padding: var(--block-pad);
		
		@media only screen and (max-width: 56.25em) {
			padding: var(--block-pad-sm);
		}
	}
	
	&__bt-message {
		text-align: center;
		font-size: 1.4rem;
	}
	
	&__bt-btn {
		font-size: 1.6rem;
		text-transform: uppercase;
		color: var(--color-white);
		border: none;
		background-color: var(--color-primary);
		padding: 2rem;
		cursor: pointer;
		transition: all .5s;
		display: inline-block;
		
		&:hover {
			background-color: var(--color-secondary);
			color: var(--color-black);
		}
		
		&:focus {
			outline: none;
			transform: scale(1.2);
		}
		
		&--hidden {
			display: none;
		}
	}
}